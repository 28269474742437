import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { getImgProps } from "helpers/getImgProps";
import * as styles from "./impact.module.scss";

const Impact = ({ text, image }) => {
  return (
    <div className={`${styles.wrapper} `}>
      <div
        className={`${styles.info} mt-80 mb-150 d-flex  flex-md-row flex-column-reverse justify-content-md-around align-items-md-center`}
      >
        <div className={styles.articleContainer}>
          <p className={styles.text}>{text}</p>
        </div>
        <div className={styles.imgContainer}>
          <GatsbyImage {...getImgProps(image)} />
        </div>
      </div>
    </div>
  );
};

export default Impact;
