// extracted by mini-css-extract-plugin
export var articleContainer = "impact-module--articleContainer--722a0";
export var imgContainer = "impact-module--imgContainer--8fa26";
export var info = "impact-module--info--9d9c2";
export var ma0 = "impact-module--ma-0--c2cea";
export var ma10 = "impact-module--ma-10--12d36";
export var ma100 = "impact-module--ma-100--1527b";
export var ma105 = "impact-module--ma-105--061be";
export var ma110 = "impact-module--ma-110--57a6c";
export var ma115 = "impact-module--ma-115--0d9a7";
export var ma12 = "impact-module--ma-12--b6c83";
export var ma120 = "impact-module--ma-120--dee1a";
export var ma130 = "impact-module--ma-130--b2339";
export var ma140 = "impact-module--ma-140--c0608";
export var ma15 = "impact-module--ma-15--ee4ac";
export var ma150 = "impact-module--ma-150--58e46";
export var ma16 = "impact-module--ma-16--03b8d";
export var ma18 = "impact-module--ma-18--aa56a";
export var ma185 = "impact-module--ma-185--a477b";
export var ma20 = "impact-module--ma-20--d01f3";
export var ma25 = "impact-module--ma-25--54692";
export var ma30 = "impact-module--ma-30--1d5ac";
export var ma35 = "impact-module--ma-35--32cb1";
export var ma40 = "impact-module--ma-40--ec557";
export var ma45 = "impact-module--ma-45--2a4de";
export var ma48 = "impact-module--ma-48--d7319";
export var ma5 = "impact-module--ma-5--3eafc";
export var ma50 = "impact-module--ma-50--80728";
export var ma55 = "impact-module--ma-55--c2000";
export var ma60 = "impact-module--ma-60--85194";
export var ma70 = "impact-module--ma-70--27871";
export var ma80 = "impact-module--ma-80--73bfe";
export var ma85 = "impact-module--ma-85--43a6b";
export var ma90 = "impact-module--ma-90--73da4";
export var ma95 = "impact-module--ma-95--8f8c5";
export var mb0 = "impact-module--mb-0--15b3a";
export var mb10 = "impact-module--mb-10--72dac";
export var mb100 = "impact-module--mb-100--652a4";
export var mb105 = "impact-module--mb-105--60c13";
export var mb110 = "impact-module--mb-110--86b87";
export var mb115 = "impact-module--mb-115--01ffc";
export var mb12 = "impact-module--mb-12--6ed85";
export var mb120 = "impact-module--mb-120--5a5c9";
export var mb130 = "impact-module--mb-130--bd780";
export var mb140 = "impact-module--mb-140--be66a";
export var mb15 = "impact-module--mb-15--3e402";
export var mb150 = "impact-module--mb-150--0002f";
export var mb16 = "impact-module--mb-16--bf76c";
export var mb18 = "impact-module--mb-18--795f8";
export var mb185 = "impact-module--mb-185--4f563";
export var mb20 = "impact-module--mb-20--4438c";
export var mb25 = "impact-module--mb-25--daa02";
export var mb30 = "impact-module--mb-30--98928";
export var mb35 = "impact-module--mb-35--83415";
export var mb40 = "impact-module--mb-40--3ce88";
export var mb45 = "impact-module--mb-45--3751f";
export var mb48 = "impact-module--mb-48--06399";
export var mb5 = "impact-module--mb-5--8c59d";
export var mb50 = "impact-module--mb-50--4ceb9";
export var mb55 = "impact-module--mb-55--7e755";
export var mb60 = "impact-module--mb-60--0035f";
export var mb70 = "impact-module--mb-70--a3b04";
export var mb80 = "impact-module--mb-80--5c660";
export var mb85 = "impact-module--mb-85--2df90";
export var mb90 = "impact-module--mb-90--9b0c3";
export var mb95 = "impact-module--mb-95--d1667";
export var mh0 = "impact-module--mh-0--19581";
export var mh10 = "impact-module--mh-10--d98df";
export var mh100 = "impact-module--mh-100--7944f";
export var mh105 = "impact-module--mh-105--444c8";
export var mh110 = "impact-module--mh-110--e28b3";
export var mh115 = "impact-module--mh-115--c2e1e";
export var mh12 = "impact-module--mh-12--ea249";
export var mh120 = "impact-module--mh-120--5b73e";
export var mh130 = "impact-module--mh-130--74fe1";
export var mh140 = "impact-module--mh-140--b1999";
export var mh15 = "impact-module--mh-15--b9931";
export var mh150 = "impact-module--mh-150--b04b5";
export var mh16 = "impact-module--mh-16--e8ce3";
export var mh18 = "impact-module--mh-18--2fb83";
export var mh185 = "impact-module--mh-185--0627f";
export var mh20 = "impact-module--mh-20--2c937";
export var mh25 = "impact-module--mh-25--2e9bd";
export var mh30 = "impact-module--mh-30--3f04f";
export var mh35 = "impact-module--mh-35--953b8";
export var mh40 = "impact-module--mh-40--8adf0";
export var mh45 = "impact-module--mh-45--f3970";
export var mh48 = "impact-module--mh-48--86594";
export var mh5 = "impact-module--mh-5--95e70";
export var mh50 = "impact-module--mh-50--55489";
export var mh55 = "impact-module--mh-55--48a1f";
export var mh60 = "impact-module--mh-60--f1f82";
export var mh70 = "impact-module--mh-70--c1d2d";
export var mh80 = "impact-module--mh-80--9d6e8";
export var mh85 = "impact-module--mh-85--a7795";
export var mh90 = "impact-module--mh-90--5e884";
export var mh95 = "impact-module--mh-95--442ad";
export var ml0 = "impact-module--ml-0--b9b59";
export var ml10 = "impact-module--ml-10--6c698";
export var ml100 = "impact-module--ml-100--355ce";
export var ml105 = "impact-module--ml-105--de899";
export var ml110 = "impact-module--ml-110--914d5";
export var ml115 = "impact-module--ml-115--6b0f8";
export var ml12 = "impact-module--ml-12--527b2";
export var ml120 = "impact-module--ml-120--fcf89";
export var ml130 = "impact-module--ml-130--0d4ec";
export var ml140 = "impact-module--ml-140--9ff02";
export var ml15 = "impact-module--ml-15--343f9";
export var ml150 = "impact-module--ml-150--22e4b";
export var ml16 = "impact-module--ml-16--d6fec";
export var ml18 = "impact-module--ml-18--b6e8e";
export var ml185 = "impact-module--ml-185--f4e4a";
export var ml20 = "impact-module--ml-20--d6020";
export var ml25 = "impact-module--ml-25--30849";
export var ml30 = "impact-module--ml-30--b3780";
export var ml35 = "impact-module--ml-35--e66e4";
export var ml40 = "impact-module--ml-40--98ace";
export var ml45 = "impact-module--ml-45--ad699";
export var ml48 = "impact-module--ml-48--f3bb7";
export var ml5 = "impact-module--ml-5--2ce63";
export var ml50 = "impact-module--ml-50--fce11";
export var ml55 = "impact-module--ml-55--fc39a";
export var ml60 = "impact-module--ml-60--9ce87";
export var ml70 = "impact-module--ml-70--81ffe";
export var ml80 = "impact-module--ml-80--21f41";
export var ml85 = "impact-module--ml-85--849cb";
export var ml90 = "impact-module--ml-90--6fef7";
export var ml95 = "impact-module--ml-95--64975";
export var mr0 = "impact-module--mr-0--660ed";
export var mr10 = "impact-module--mr-10--48988";
export var mr100 = "impact-module--mr-100--31c2c";
export var mr105 = "impact-module--mr-105--c1c70";
export var mr110 = "impact-module--mr-110--92cad";
export var mr115 = "impact-module--mr-115--16923";
export var mr12 = "impact-module--mr-12--651f6";
export var mr120 = "impact-module--mr-120--62d81";
export var mr130 = "impact-module--mr-130--4b460";
export var mr140 = "impact-module--mr-140--eaad7";
export var mr15 = "impact-module--mr-15--1357b";
export var mr150 = "impact-module--mr-150--43e7b";
export var mr16 = "impact-module--mr-16--3317d";
export var mr18 = "impact-module--mr-18--08dda";
export var mr185 = "impact-module--mr-185--9f1a9";
export var mr20 = "impact-module--mr-20--33df3";
export var mr25 = "impact-module--mr-25--d7128";
export var mr30 = "impact-module--mr-30--97dfa";
export var mr35 = "impact-module--mr-35--f3255";
export var mr40 = "impact-module--mr-40--e964a";
export var mr45 = "impact-module--mr-45--b4b05";
export var mr48 = "impact-module--mr-48--41e88";
export var mr5 = "impact-module--mr-5--91f43";
export var mr50 = "impact-module--mr-50--e1801";
export var mr55 = "impact-module--mr-55--433e7";
export var mr60 = "impact-module--mr-60--7f998";
export var mr70 = "impact-module--mr-70--0e6a9";
export var mr80 = "impact-module--mr-80--0a0ba";
export var mr85 = "impact-module--mr-85--388ff";
export var mr90 = "impact-module--mr-90--bc8d2";
export var mr95 = "impact-module--mr-95--9875a";
export var mt0 = "impact-module--mt-0--5ed98";
export var mt10 = "impact-module--mt-10--304b6";
export var mt100 = "impact-module--mt-100--da0e1";
export var mt105 = "impact-module--mt-105--da40d";
export var mt110 = "impact-module--mt-110--36874";
export var mt115 = "impact-module--mt-115--446d1";
export var mt12 = "impact-module--mt-12--4254a";
export var mt120 = "impact-module--mt-120--2205e";
export var mt130 = "impact-module--mt-130--c64dd";
export var mt140 = "impact-module--mt-140--ebd3b";
export var mt15 = "impact-module--mt-15--258b7";
export var mt150 = "impact-module--mt-150--c3199";
export var mt16 = "impact-module--mt-16--a004f";
export var mt18 = "impact-module--mt-18--09647";
export var mt185 = "impact-module--mt-185--c13e8";
export var mt20 = "impact-module--mt-20--c3501";
export var mt25 = "impact-module--mt-25--222a8";
export var mt30 = "impact-module--mt-30--3256e";
export var mt35 = "impact-module--mt-35--4d59b";
export var mt40 = "impact-module--mt-40--ae005";
export var mt45 = "impact-module--mt-45--9f019";
export var mt48 = "impact-module--mt-48--dfcf0";
export var mt5 = "impact-module--mt-5--50bf5";
export var mt50 = "impact-module--mt-50--fcb10";
export var mt55 = "impact-module--mt-55--cb1e0";
export var mt60 = "impact-module--mt-60--c394b";
export var mt70 = "impact-module--mt-70--d42cd";
export var mt80 = "impact-module--mt-80--54e9c";
export var mt85 = "impact-module--mt-85--21bab";
export var mt90 = "impact-module--mt-90--0de2d";
export var mt95 = "impact-module--mt-95--d8e56";
export var mv0 = "impact-module--mv-0--29fff";
export var mv10 = "impact-module--mv-10--709b7";
export var mv100 = "impact-module--mv-100--e5398";
export var mv105 = "impact-module--mv-105--8ddf0";
export var mv110 = "impact-module--mv-110--e19c7";
export var mv115 = "impact-module--mv-115--bf199";
export var mv12 = "impact-module--mv-12--17e7d";
export var mv120 = "impact-module--mv-120--794ee";
export var mv130 = "impact-module--mv-130--0e95e";
export var mv140 = "impact-module--mv-140--7662d";
export var mv15 = "impact-module--mv-15--fd4b3";
export var mv150 = "impact-module--mv-150--a68d6";
export var mv16 = "impact-module--mv-16--4abfd";
export var mv18 = "impact-module--mv-18--06223";
export var mv185 = "impact-module--mv-185--c36dc";
export var mv20 = "impact-module--mv-20--3f61d";
export var mv25 = "impact-module--mv-25--e2601";
export var mv30 = "impact-module--mv-30--31157";
export var mv35 = "impact-module--mv-35--71d25";
export var mv40 = "impact-module--mv-40--f3174";
export var mv45 = "impact-module--mv-45--750ad";
export var mv48 = "impact-module--mv-48--f5914";
export var mv5 = "impact-module--mv-5--3acfe";
export var mv50 = "impact-module--mv-50--784c1";
export var mv55 = "impact-module--mv-55--baf2f";
export var mv60 = "impact-module--mv-60--41297";
export var mv70 = "impact-module--mv-70--88a41";
export var mv80 = "impact-module--mv-80--fe313";
export var mv85 = "impact-module--mv-85--a1dff";
export var mv90 = "impact-module--mv-90--16a72";
export var mv95 = "impact-module--mv-95--b0344";
export var pa0 = "impact-module--pa-0--e3b53";
export var pa10 = "impact-module--pa-10--e6aff";
export var pa100 = "impact-module--pa-100--bf89d";
export var pa105 = "impact-module--pa-105--3e107";
export var pa110 = "impact-module--pa-110--e3f76";
export var pa115 = "impact-module--pa-115--b78f3";
export var pa12 = "impact-module--pa-12--5a193";
export var pa120 = "impact-module--pa-120--e8d88";
export var pa130 = "impact-module--pa-130--1de9c";
export var pa140 = "impact-module--pa-140--abcda";
export var pa15 = "impact-module--pa-15--f466a";
export var pa150 = "impact-module--pa-150--d1af7";
export var pa16 = "impact-module--pa-16--1e956";
export var pa18 = "impact-module--pa-18--b7899";
export var pa185 = "impact-module--pa-185--88acb";
export var pa20 = "impact-module--pa-20--bb2e0";
export var pa25 = "impact-module--pa-25--d0fd5";
export var pa30 = "impact-module--pa-30--afeae";
export var pa35 = "impact-module--pa-35--7b0f6";
export var pa40 = "impact-module--pa-40--b51ca";
export var pa45 = "impact-module--pa-45--35e6d";
export var pa48 = "impact-module--pa-48--c5802";
export var pa5 = "impact-module--pa-5--880cf";
export var pa50 = "impact-module--pa-50--3a238";
export var pa55 = "impact-module--pa-55--c4292";
export var pa60 = "impact-module--pa-60--e06a8";
export var pa70 = "impact-module--pa-70--b64f7";
export var pa80 = "impact-module--pa-80--16514";
export var pa85 = "impact-module--pa-85--1f93b";
export var pa90 = "impact-module--pa-90--9612e";
export var pa95 = "impact-module--pa-95--68119";
export var pb0 = "impact-module--pb-0--0c8dd";
export var pb10 = "impact-module--pb-10--ba3ec";
export var pb100 = "impact-module--pb-100--6f443";
export var pb105 = "impact-module--pb-105--47455";
export var pb110 = "impact-module--pb-110--702ad";
export var pb115 = "impact-module--pb-115--5988a";
export var pb12 = "impact-module--pb-12--6edd8";
export var pb120 = "impact-module--pb-120--db0ef";
export var pb130 = "impact-module--pb-130--b9034";
export var pb140 = "impact-module--pb-140--802fd";
export var pb15 = "impact-module--pb-15--4e4c2";
export var pb150 = "impact-module--pb-150--1f3ec";
export var pb16 = "impact-module--pb-16--c349d";
export var pb18 = "impact-module--pb-18--b2b98";
export var pb185 = "impact-module--pb-185--bbc2c";
export var pb20 = "impact-module--pb-20--38a54";
export var pb25 = "impact-module--pb-25--da876";
export var pb30 = "impact-module--pb-30--0a7a8";
export var pb35 = "impact-module--pb-35--d04cb";
export var pb40 = "impact-module--pb-40--f5651";
export var pb45 = "impact-module--pb-45--bd928";
export var pb48 = "impact-module--pb-48--2c1c8";
export var pb5 = "impact-module--pb-5--f1b31";
export var pb50 = "impact-module--pb-50--38572";
export var pb55 = "impact-module--pb-55--ffd44";
export var pb60 = "impact-module--pb-60--0139d";
export var pb70 = "impact-module--pb-70--e76fa";
export var pb80 = "impact-module--pb-80--d81d2";
export var pb85 = "impact-module--pb-85--b9feb";
export var pb90 = "impact-module--pb-90--94f7c";
export var pb95 = "impact-module--pb-95--73e93";
export var ph0 = "impact-module--ph-0--3493f";
export var ph10 = "impact-module--ph-10--68b62";
export var ph100 = "impact-module--ph-100--1b83f";
export var ph105 = "impact-module--ph-105--135ba";
export var ph110 = "impact-module--ph-110--3c1e7";
export var ph115 = "impact-module--ph-115--440c5";
export var ph12 = "impact-module--ph-12--56fa2";
export var ph120 = "impact-module--ph-120--d63cb";
export var ph130 = "impact-module--ph-130--9e18d";
export var ph140 = "impact-module--ph-140--fc3d0";
export var ph15 = "impact-module--ph-15--d36ec";
export var ph150 = "impact-module--ph-150--5db32";
export var ph16 = "impact-module--ph-16--3936a";
export var ph18 = "impact-module--ph-18--891be";
export var ph185 = "impact-module--ph-185--c7241";
export var ph20 = "impact-module--ph-20--e3195";
export var ph25 = "impact-module--ph-25--07ca8";
export var ph30 = "impact-module--ph-30--a7b8c";
export var ph35 = "impact-module--ph-35--37318";
export var ph40 = "impact-module--ph-40--8c052";
export var ph45 = "impact-module--ph-45--fa881";
export var ph48 = "impact-module--ph-48--1c4cf";
export var ph5 = "impact-module--ph-5--f12f4";
export var ph50 = "impact-module--ph-50--de36e";
export var ph55 = "impact-module--ph-55--157d5";
export var ph60 = "impact-module--ph-60--a7ebe";
export var ph70 = "impact-module--ph-70--e1af3";
export var ph80 = "impact-module--ph-80--569c0";
export var ph85 = "impact-module--ph-85--76f3c";
export var ph90 = "impact-module--ph-90--69627";
export var ph95 = "impact-module--ph-95--5613b";
export var pl0 = "impact-module--pl-0--1a9ca";
export var pl10 = "impact-module--pl-10--7f5b6";
export var pl100 = "impact-module--pl-100--dbfe8";
export var pl105 = "impact-module--pl-105--2c33f";
export var pl110 = "impact-module--pl-110--15dcc";
export var pl115 = "impact-module--pl-115--41477";
export var pl12 = "impact-module--pl-12--3fb63";
export var pl120 = "impact-module--pl-120--adc36";
export var pl130 = "impact-module--pl-130--731c4";
export var pl140 = "impact-module--pl-140--f15eb";
export var pl15 = "impact-module--pl-15--3ad7f";
export var pl150 = "impact-module--pl-150--86126";
export var pl16 = "impact-module--pl-16--8bb50";
export var pl18 = "impact-module--pl-18--8f4fc";
export var pl185 = "impact-module--pl-185--2ea60";
export var pl20 = "impact-module--pl-20--c3915";
export var pl25 = "impact-module--pl-25--342e9";
export var pl30 = "impact-module--pl-30--51f26";
export var pl35 = "impact-module--pl-35--b4032";
export var pl40 = "impact-module--pl-40--13c48";
export var pl45 = "impact-module--pl-45--7ff2b";
export var pl48 = "impact-module--pl-48--e54d8";
export var pl5 = "impact-module--pl-5--983aa";
export var pl50 = "impact-module--pl-50--cddbb";
export var pl55 = "impact-module--pl-55--32d3a";
export var pl60 = "impact-module--pl-60--20052";
export var pl70 = "impact-module--pl-70--990b6";
export var pl80 = "impact-module--pl-80--8deb4";
export var pl85 = "impact-module--pl-85--da687";
export var pl90 = "impact-module--pl-90--19e55";
export var pl95 = "impact-module--pl-95--7446c";
export var pr0 = "impact-module--pr-0--09677";
export var pr10 = "impact-module--pr-10--1f79f";
export var pr100 = "impact-module--pr-100--ebd12";
export var pr105 = "impact-module--pr-105--1bcd9";
export var pr110 = "impact-module--pr-110--89fe9";
export var pr115 = "impact-module--pr-115--a043e";
export var pr12 = "impact-module--pr-12--08c6e";
export var pr120 = "impact-module--pr-120--48d8d";
export var pr130 = "impact-module--pr-130--0ae57";
export var pr140 = "impact-module--pr-140--ec4ed";
export var pr15 = "impact-module--pr-15--0a3fe";
export var pr150 = "impact-module--pr-150--693ff";
export var pr16 = "impact-module--pr-16--1d2c2";
export var pr18 = "impact-module--pr-18--6973f";
export var pr185 = "impact-module--pr-185--c876c";
export var pr20 = "impact-module--pr-20--b47a3";
export var pr25 = "impact-module--pr-25--73cd0";
export var pr30 = "impact-module--pr-30--79792";
export var pr35 = "impact-module--pr-35--2dba8";
export var pr40 = "impact-module--pr-40--06ec3";
export var pr45 = "impact-module--pr-45--6876f";
export var pr48 = "impact-module--pr-48--7247f";
export var pr5 = "impact-module--pr-5--ce1df";
export var pr50 = "impact-module--pr-50--f63e2";
export var pr55 = "impact-module--pr-55--2aee4";
export var pr60 = "impact-module--pr-60--4f9e2";
export var pr70 = "impact-module--pr-70--94529";
export var pr80 = "impact-module--pr-80--5882f";
export var pr85 = "impact-module--pr-85--6264e";
export var pr90 = "impact-module--pr-90--928b7";
export var pr95 = "impact-module--pr-95--7f5c5";
export var pt0 = "impact-module--pt-0--5aaac";
export var pt10 = "impact-module--pt-10--dff7d";
export var pt100 = "impact-module--pt-100--84ba5";
export var pt105 = "impact-module--pt-105--0ef12";
export var pt110 = "impact-module--pt-110--10abe";
export var pt115 = "impact-module--pt-115--20ada";
export var pt12 = "impact-module--pt-12--ae9de";
export var pt120 = "impact-module--pt-120--4a6d3";
export var pt130 = "impact-module--pt-130--2850e";
export var pt140 = "impact-module--pt-140--b99bf";
export var pt15 = "impact-module--pt-15--5bfd5";
export var pt150 = "impact-module--pt-150--bb0c4";
export var pt16 = "impact-module--pt-16--7aa8b";
export var pt18 = "impact-module--pt-18--dd5f6";
export var pt185 = "impact-module--pt-185--b91ce";
export var pt20 = "impact-module--pt-20--b54cc";
export var pt25 = "impact-module--pt-25--fc8de";
export var pt30 = "impact-module--pt-30--c2a7e";
export var pt35 = "impact-module--pt-35--1db70";
export var pt40 = "impact-module--pt-40--4bee7";
export var pt45 = "impact-module--pt-45--0752a";
export var pt48 = "impact-module--pt-48--778c2";
export var pt5 = "impact-module--pt-5--4756b";
export var pt50 = "impact-module--pt-50--84afe";
export var pt55 = "impact-module--pt-55--16c6d";
export var pt60 = "impact-module--pt-60--d1631";
export var pt70 = "impact-module--pt-70--f9481";
export var pt80 = "impact-module--pt-80--a2ff8";
export var pt85 = "impact-module--pt-85--c9aae";
export var pt90 = "impact-module--pt-90--96d3c";
export var pt95 = "impact-module--pt-95--a2eb5";
export var pv0 = "impact-module--pv-0--24fee";
export var pv10 = "impact-module--pv-10--3720b";
export var pv100 = "impact-module--pv-100--ec944";
export var pv105 = "impact-module--pv-105--78a20";
export var pv110 = "impact-module--pv-110--1ff7c";
export var pv115 = "impact-module--pv-115--9bf91";
export var pv12 = "impact-module--pv-12--43207";
export var pv120 = "impact-module--pv-120--32a1d";
export var pv130 = "impact-module--pv-130--4f46d";
export var pv140 = "impact-module--pv-140--309f5";
export var pv15 = "impact-module--pv-15--e5498";
export var pv150 = "impact-module--pv-150--20bff";
export var pv16 = "impact-module--pv-16--9126e";
export var pv18 = "impact-module--pv-18--ae675";
export var pv185 = "impact-module--pv-185--3c98d";
export var pv20 = "impact-module--pv-20--7269f";
export var pv25 = "impact-module--pv-25--17887";
export var pv30 = "impact-module--pv-30--8f314";
export var pv35 = "impact-module--pv-35--256b2";
export var pv40 = "impact-module--pv-40--3a886";
export var pv45 = "impact-module--pv-45--abbf0";
export var pv48 = "impact-module--pv-48--0f68c";
export var pv5 = "impact-module--pv-5--c10ca";
export var pv50 = "impact-module--pv-50--f9819";
export var pv55 = "impact-module--pv-55--5010a";
export var pv60 = "impact-module--pv-60--9e3ab";
export var pv70 = "impact-module--pv-70--30985";
export var pv80 = "impact-module--pv-80--9b436";
export var pv85 = "impact-module--pv-85--b0fcb";
export var pv90 = "impact-module--pv-90--0988c";
export var pv95 = "impact-module--pv-95--c60be";
export var text = "impact-module--text--dbbb7";
export var title = "impact-module--title--23899";
export var wrapper = "impact-module--wrapper--57a73";