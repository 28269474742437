// extracted by mini-css-extract-plugin
export var bgWhite = "people-module--bgWhite--214f0";
export var imgWrapper = "people-module--imgWrapper--90345";
export var ma0 = "people-module--ma-0--81535";
export var ma10 = "people-module--ma-10--f1339";
export var ma100 = "people-module--ma-100--adc45";
export var ma105 = "people-module--ma-105--5c6cf";
export var ma110 = "people-module--ma-110--caf79";
export var ma115 = "people-module--ma-115--28c18";
export var ma12 = "people-module--ma-12--909d4";
export var ma120 = "people-module--ma-120--07991";
export var ma130 = "people-module--ma-130--56a00";
export var ma140 = "people-module--ma-140--25e42";
export var ma15 = "people-module--ma-15--fd95a";
export var ma150 = "people-module--ma-150--a8679";
export var ma16 = "people-module--ma-16--53340";
export var ma18 = "people-module--ma-18--55474";
export var ma185 = "people-module--ma-185--be7ac";
export var ma20 = "people-module--ma-20--32b43";
export var ma25 = "people-module--ma-25--70f94";
export var ma30 = "people-module--ma-30--bbab7";
export var ma35 = "people-module--ma-35--e8241";
export var ma40 = "people-module--ma-40--9f2c6";
export var ma45 = "people-module--ma-45--31cb9";
export var ma48 = "people-module--ma-48--1fa4c";
export var ma5 = "people-module--ma-5--9f70b";
export var ma50 = "people-module--ma-50--9ffdc";
export var ma55 = "people-module--ma-55--449ce";
export var ma60 = "people-module--ma-60--22978";
export var ma70 = "people-module--ma-70--986ca";
export var ma80 = "people-module--ma-80--06bfc";
export var ma85 = "people-module--ma-85--74a94";
export var ma90 = "people-module--ma-90--55653";
export var ma95 = "people-module--ma-95--1c367";
export var mb0 = "people-module--mb-0--c34db";
export var mb10 = "people-module--mb-10--9280e";
export var mb100 = "people-module--mb-100--8df9d";
export var mb105 = "people-module--mb-105--ea43f";
export var mb110 = "people-module--mb-110--2969c";
export var mb115 = "people-module--mb-115--d4180";
export var mb12 = "people-module--mb-12--f8c73";
export var mb120 = "people-module--mb-120--d8108";
export var mb130 = "people-module--mb-130--b6bb8";
export var mb140 = "people-module--mb-140--50a3a";
export var mb15 = "people-module--mb-15--1e06f";
export var mb150 = "people-module--mb-150--67532";
export var mb16 = "people-module--mb-16--22d64";
export var mb18 = "people-module--mb-18--00e9e";
export var mb185 = "people-module--mb-185--a69a2";
export var mb20 = "people-module--mb-20--221bd";
export var mb25 = "people-module--mb-25--68711";
export var mb30 = "people-module--mb-30--5218f";
export var mb35 = "people-module--mb-35--90649";
export var mb40 = "people-module--mb-40--156fc";
export var mb45 = "people-module--mb-45--8ab99";
export var mb48 = "people-module--mb-48--1b61f";
export var mb5 = "people-module--mb-5--d8123";
export var mb50 = "people-module--mb-50--711b4";
export var mb55 = "people-module--mb-55--c9232";
export var mb60 = "people-module--mb-60--a1240";
export var mb70 = "people-module--mb-70--cf729";
export var mb80 = "people-module--mb-80--01dec";
export var mb85 = "people-module--mb-85--eca72";
export var mb90 = "people-module--mb-90--47fe7";
export var mb95 = "people-module--mb-95--faad7";
export var mh0 = "people-module--mh-0--55a5c";
export var mh10 = "people-module--mh-10--c1931";
export var mh100 = "people-module--mh-100--d485b";
export var mh105 = "people-module--mh-105--217c0";
export var mh110 = "people-module--mh-110--2c242";
export var mh115 = "people-module--mh-115--2aaa9";
export var mh12 = "people-module--mh-12--b5758";
export var mh120 = "people-module--mh-120--e0dfe";
export var mh130 = "people-module--mh-130--d4040";
export var mh140 = "people-module--mh-140--a2dfe";
export var mh15 = "people-module--mh-15--131f5";
export var mh150 = "people-module--mh-150--efaea";
export var mh16 = "people-module--mh-16--e291b";
export var mh18 = "people-module--mh-18--98f05";
export var mh185 = "people-module--mh-185--8e304";
export var mh20 = "people-module--mh-20--d8d87";
export var mh25 = "people-module--mh-25--dde1d";
export var mh30 = "people-module--mh-30--ed835";
export var mh35 = "people-module--mh-35--0fa23";
export var mh40 = "people-module--mh-40--007ce";
export var mh45 = "people-module--mh-45--c1c54";
export var mh48 = "people-module--mh-48--722d6";
export var mh5 = "people-module--mh-5--7f75b";
export var mh50 = "people-module--mh-50--e1b79";
export var mh55 = "people-module--mh-55--94e88";
export var mh60 = "people-module--mh-60--0a7be";
export var mh70 = "people-module--mh-70--e41dc";
export var mh80 = "people-module--mh-80--89171";
export var mh85 = "people-module--mh-85--9db62";
export var mh90 = "people-module--mh-90--94756";
export var mh95 = "people-module--mh-95--70d13";
export var ml0 = "people-module--ml-0--5434c";
export var ml10 = "people-module--ml-10--94e57";
export var ml100 = "people-module--ml-100--6e037";
export var ml105 = "people-module--ml-105--dac92";
export var ml110 = "people-module--ml-110--270de";
export var ml115 = "people-module--ml-115--130bc";
export var ml12 = "people-module--ml-12--fa0d2";
export var ml120 = "people-module--ml-120--42bb5";
export var ml130 = "people-module--ml-130--47638";
export var ml140 = "people-module--ml-140--6aec4";
export var ml15 = "people-module--ml-15--712c2";
export var ml150 = "people-module--ml-150--4cde6";
export var ml16 = "people-module--ml-16--c7000";
export var ml18 = "people-module--ml-18--0e067";
export var ml185 = "people-module--ml-185--b2c5d";
export var ml20 = "people-module--ml-20--b034b";
export var ml25 = "people-module--ml-25--a2922";
export var ml30 = "people-module--ml-30--a320c";
export var ml35 = "people-module--ml-35--bc7d5";
export var ml40 = "people-module--ml-40--7f867";
export var ml45 = "people-module--ml-45--59424";
export var ml48 = "people-module--ml-48--319be";
export var ml5 = "people-module--ml-5--cc93a";
export var ml50 = "people-module--ml-50--d0dba";
export var ml55 = "people-module--ml-55--06969";
export var ml60 = "people-module--ml-60--c09b4";
export var ml70 = "people-module--ml-70--87f1b";
export var ml80 = "people-module--ml-80--c1db4";
export var ml85 = "people-module--ml-85--8d5b1";
export var ml90 = "people-module--ml-90--048ad";
export var ml95 = "people-module--ml-95--dd04f";
export var mr0 = "people-module--mr-0--e5952";
export var mr10 = "people-module--mr-10--a3a03";
export var mr100 = "people-module--mr-100--d22fa";
export var mr105 = "people-module--mr-105--66d73";
export var mr110 = "people-module--mr-110--c0ddc";
export var mr115 = "people-module--mr-115--7c1a5";
export var mr12 = "people-module--mr-12--8a9c3";
export var mr120 = "people-module--mr-120--42287";
export var mr130 = "people-module--mr-130--4bf8a";
export var mr140 = "people-module--mr-140--2dd4d";
export var mr15 = "people-module--mr-15--eb092";
export var mr150 = "people-module--mr-150--44bec";
export var mr16 = "people-module--mr-16--68390";
export var mr18 = "people-module--mr-18--848f0";
export var mr185 = "people-module--mr-185--8cea5";
export var mr20 = "people-module--mr-20--971ef";
export var mr25 = "people-module--mr-25--3420f";
export var mr30 = "people-module--mr-30--b3d3e";
export var mr35 = "people-module--mr-35--0d1e7";
export var mr40 = "people-module--mr-40--51a14";
export var mr45 = "people-module--mr-45--ff72d";
export var mr48 = "people-module--mr-48--139ec";
export var mr5 = "people-module--mr-5--99848";
export var mr50 = "people-module--mr-50--4cedd";
export var mr55 = "people-module--mr-55--ed999";
export var mr60 = "people-module--mr-60--54c6a";
export var mr70 = "people-module--mr-70--6d634";
export var mr80 = "people-module--mr-80--a800f";
export var mr85 = "people-module--mr-85--e4f0a";
export var mr90 = "people-module--mr-90--41ba0";
export var mr95 = "people-module--mr-95--6d74c";
export var mt0 = "people-module--mt-0--ecfa4";
export var mt10 = "people-module--mt-10--517e3";
export var mt100 = "people-module--mt-100--216ae";
export var mt105 = "people-module--mt-105--ddd18";
export var mt110 = "people-module--mt-110--eb58e";
export var mt115 = "people-module--mt-115--6cf92";
export var mt12 = "people-module--mt-12--801b6";
export var mt120 = "people-module--mt-120--e28e1";
export var mt130 = "people-module--mt-130--4206f";
export var mt140 = "people-module--mt-140--61ab3";
export var mt15 = "people-module--mt-15--3a0f2";
export var mt150 = "people-module--mt-150--e3e95";
export var mt16 = "people-module--mt-16--be5ba";
export var mt18 = "people-module--mt-18--1f4ff";
export var mt185 = "people-module--mt-185--20224";
export var mt20 = "people-module--mt-20--3b716";
export var mt25 = "people-module--mt-25--4dc25";
export var mt30 = "people-module--mt-30--0cd26";
export var mt35 = "people-module--mt-35--4b456";
export var mt40 = "people-module--mt-40--774bd";
export var mt45 = "people-module--mt-45--d728a";
export var mt48 = "people-module--mt-48--64ee1";
export var mt5 = "people-module--mt-5--ca66c";
export var mt50 = "people-module--mt-50--c7e07";
export var mt55 = "people-module--mt-55--65976";
export var mt60 = "people-module--mt-60--276d6";
export var mt70 = "people-module--mt-70--b3526";
export var mt80 = "people-module--mt-80--f1032";
export var mt85 = "people-module--mt-85--97b93";
export var mt90 = "people-module--mt-90--7d4fc";
export var mt95 = "people-module--mt-95--64cdf";
export var mv0 = "people-module--mv-0--e2bdc";
export var mv10 = "people-module--mv-10--a5afd";
export var mv100 = "people-module--mv-100--d3b98";
export var mv105 = "people-module--mv-105--a1c7d";
export var mv110 = "people-module--mv-110--68250";
export var mv115 = "people-module--mv-115--63d64";
export var mv12 = "people-module--mv-12--fd197";
export var mv120 = "people-module--mv-120--bb2e1";
export var mv130 = "people-module--mv-130--d316d";
export var mv140 = "people-module--mv-140--685a2";
export var mv15 = "people-module--mv-15--3499b";
export var mv150 = "people-module--mv-150--462a7";
export var mv16 = "people-module--mv-16--39d13";
export var mv18 = "people-module--mv-18--e2e5f";
export var mv185 = "people-module--mv-185--0724e";
export var mv20 = "people-module--mv-20--1beeb";
export var mv25 = "people-module--mv-25--56879";
export var mv30 = "people-module--mv-30--1f4c9";
export var mv35 = "people-module--mv-35--6648c";
export var mv40 = "people-module--mv-40--17171";
export var mv45 = "people-module--mv-45--598e0";
export var mv48 = "people-module--mv-48--0ce55";
export var mv5 = "people-module--mv-5--9f762";
export var mv50 = "people-module--mv-50--205d2";
export var mv55 = "people-module--mv-55--7fc4f";
export var mv60 = "people-module--mv-60--dcb95";
export var mv70 = "people-module--mv-70--c9409";
export var mv80 = "people-module--mv-80--67c75";
export var mv85 = "people-module--mv-85--2604f";
export var mv90 = "people-module--mv-90--57356";
export var mv95 = "people-module--mv-95--69772";
export var pa0 = "people-module--pa-0--c89b6";
export var pa10 = "people-module--pa-10--988f9";
export var pa100 = "people-module--pa-100--babb4";
export var pa105 = "people-module--pa-105--22ddd";
export var pa110 = "people-module--pa-110--52099";
export var pa115 = "people-module--pa-115--b2659";
export var pa12 = "people-module--pa-12--582a8";
export var pa120 = "people-module--pa-120--cf37f";
export var pa130 = "people-module--pa-130--276e1";
export var pa140 = "people-module--pa-140--bfde7";
export var pa15 = "people-module--pa-15--5180a";
export var pa150 = "people-module--pa-150--2fee8";
export var pa16 = "people-module--pa-16--b25b8";
export var pa18 = "people-module--pa-18--8ecbd";
export var pa185 = "people-module--pa-185--8f66a";
export var pa20 = "people-module--pa-20--c663d";
export var pa25 = "people-module--pa-25--0be1f";
export var pa30 = "people-module--pa-30--059b8";
export var pa35 = "people-module--pa-35--10cab";
export var pa40 = "people-module--pa-40--258ba";
export var pa45 = "people-module--pa-45--46a05";
export var pa48 = "people-module--pa-48--8aad2";
export var pa5 = "people-module--pa-5--2beed";
export var pa50 = "people-module--pa-50--ee1f8";
export var pa55 = "people-module--pa-55--d713b";
export var pa60 = "people-module--pa-60--aa432";
export var pa70 = "people-module--pa-70--bad05";
export var pa80 = "people-module--pa-80--f9a58";
export var pa85 = "people-module--pa-85--4229e";
export var pa90 = "people-module--pa-90--57014";
export var pa95 = "people-module--pa-95--8a81b";
export var pb0 = "people-module--pb-0--0c3bc";
export var pb10 = "people-module--pb-10--cecc1";
export var pb100 = "people-module--pb-100--f38c2";
export var pb105 = "people-module--pb-105--2ecba";
export var pb110 = "people-module--pb-110--08750";
export var pb115 = "people-module--pb-115--5d078";
export var pb12 = "people-module--pb-12--47647";
export var pb120 = "people-module--pb-120--f5071";
export var pb130 = "people-module--pb-130--68a61";
export var pb140 = "people-module--pb-140--f88ea";
export var pb15 = "people-module--pb-15--f6db8";
export var pb150 = "people-module--pb-150--dd5a6";
export var pb16 = "people-module--pb-16--8280f";
export var pb18 = "people-module--pb-18--54fa7";
export var pb185 = "people-module--pb-185--3ffb6";
export var pb20 = "people-module--pb-20--edab2";
export var pb25 = "people-module--pb-25--27eab";
export var pb30 = "people-module--pb-30--a000e";
export var pb35 = "people-module--pb-35--230a1";
export var pb40 = "people-module--pb-40--f115e";
export var pb45 = "people-module--pb-45--d7468";
export var pb48 = "people-module--pb-48--5a2be";
export var pb5 = "people-module--pb-5--76f66";
export var pb50 = "people-module--pb-50--30e7d";
export var pb55 = "people-module--pb-55--7a7c7";
export var pb60 = "people-module--pb-60--b68ca";
export var pb70 = "people-module--pb-70--1a7ff";
export var pb80 = "people-module--pb-80--26e6f";
export var pb85 = "people-module--pb-85--6d885";
export var pb90 = "people-module--pb-90--6eebe";
export var pb95 = "people-module--pb-95--cb1e0";
export var people = "people-module--people--3701f";
export var ph0 = "people-module--ph-0--f56a2";
export var ph10 = "people-module--ph-10--f4b4c";
export var ph100 = "people-module--ph-100--5a33c";
export var ph105 = "people-module--ph-105--5d61d";
export var ph110 = "people-module--ph-110--9ffde";
export var ph115 = "people-module--ph-115--1291b";
export var ph12 = "people-module--ph-12--eee55";
export var ph120 = "people-module--ph-120--e5c26";
export var ph130 = "people-module--ph-130--1eca0";
export var ph140 = "people-module--ph-140--b83c3";
export var ph15 = "people-module--ph-15--f6174";
export var ph150 = "people-module--ph-150--8db6c";
export var ph16 = "people-module--ph-16--7762f";
export var ph18 = "people-module--ph-18--d6d90";
export var ph185 = "people-module--ph-185--f44eb";
export var ph20 = "people-module--ph-20--01665";
export var ph25 = "people-module--ph-25--d2750";
export var ph30 = "people-module--ph-30--e544d";
export var ph35 = "people-module--ph-35--a367e";
export var ph40 = "people-module--ph-40--1e5a8";
export var ph45 = "people-module--ph-45--352d7";
export var ph48 = "people-module--ph-48--2fbb1";
export var ph5 = "people-module--ph-5--cae90";
export var ph50 = "people-module--ph-50--5cba2";
export var ph55 = "people-module--ph-55--8f66e";
export var ph60 = "people-module--ph-60--9b837";
export var ph70 = "people-module--ph-70--821aa";
export var ph80 = "people-module--ph-80--e1640";
export var ph85 = "people-module--ph-85--f15eb";
export var ph90 = "people-module--ph-90--4fd9a";
export var ph95 = "people-module--ph-95--ccb10";
export var pl0 = "people-module--pl-0--61c21";
export var pl10 = "people-module--pl-10--c6bf1";
export var pl100 = "people-module--pl-100--b5f70";
export var pl105 = "people-module--pl-105--57bc5";
export var pl110 = "people-module--pl-110--0c576";
export var pl115 = "people-module--pl-115--023fa";
export var pl12 = "people-module--pl-12--00646";
export var pl120 = "people-module--pl-120--80136";
export var pl130 = "people-module--pl-130--0a666";
export var pl140 = "people-module--pl-140--921e6";
export var pl15 = "people-module--pl-15--d4a1a";
export var pl150 = "people-module--pl-150--8a90c";
export var pl16 = "people-module--pl-16--44b9a";
export var pl18 = "people-module--pl-18--b2e7b";
export var pl185 = "people-module--pl-185--2e9e4";
export var pl20 = "people-module--pl-20--4d85a";
export var pl25 = "people-module--pl-25--7824a";
export var pl30 = "people-module--pl-30--8ceb7";
export var pl35 = "people-module--pl-35--9fcba";
export var pl40 = "people-module--pl-40--660cb";
export var pl45 = "people-module--pl-45--9fb63";
export var pl48 = "people-module--pl-48--80ea4";
export var pl5 = "people-module--pl-5--3e3e5";
export var pl50 = "people-module--pl-50--82de0";
export var pl55 = "people-module--pl-55--30b31";
export var pl60 = "people-module--pl-60--08e66";
export var pl70 = "people-module--pl-70--3bee4";
export var pl80 = "people-module--pl-80--540d7";
export var pl85 = "people-module--pl-85--84d68";
export var pl90 = "people-module--pl-90--844b7";
export var pl95 = "people-module--pl-95--90b1e";
export var pr0 = "people-module--pr-0--d5910";
export var pr10 = "people-module--pr-10--edb7a";
export var pr100 = "people-module--pr-100--aef49";
export var pr105 = "people-module--pr-105--673fc";
export var pr110 = "people-module--pr-110--f22a2";
export var pr115 = "people-module--pr-115--cb2cc";
export var pr12 = "people-module--pr-12--3f2b6";
export var pr120 = "people-module--pr-120--d3785";
export var pr130 = "people-module--pr-130--f4ef1";
export var pr140 = "people-module--pr-140--4d2b5";
export var pr15 = "people-module--pr-15--7f516";
export var pr150 = "people-module--pr-150--40164";
export var pr16 = "people-module--pr-16--8fc25";
export var pr18 = "people-module--pr-18--7116a";
export var pr185 = "people-module--pr-185--a57a6";
export var pr20 = "people-module--pr-20--b1cd1";
export var pr25 = "people-module--pr-25--5acd4";
export var pr30 = "people-module--pr-30--6d178";
export var pr35 = "people-module--pr-35--f29bb";
export var pr40 = "people-module--pr-40--f85df";
export var pr45 = "people-module--pr-45--c43d2";
export var pr48 = "people-module--pr-48--1caaa";
export var pr5 = "people-module--pr-5--f7b06";
export var pr50 = "people-module--pr-50--f2adc";
export var pr55 = "people-module--pr-55--79aef";
export var pr60 = "people-module--pr-60--b96ec";
export var pr70 = "people-module--pr-70--268f8";
export var pr80 = "people-module--pr-80--66d3e";
export var pr85 = "people-module--pr-85--18c44";
export var pr90 = "people-module--pr-90--7fe55";
export var pr95 = "people-module--pr-95--0168e";
export var pt0 = "people-module--pt-0--89e36";
export var pt10 = "people-module--pt-10--8e52a";
export var pt100 = "people-module--pt-100--ef60e";
export var pt105 = "people-module--pt-105--070ad";
export var pt110 = "people-module--pt-110--5f1db";
export var pt115 = "people-module--pt-115--11c57";
export var pt12 = "people-module--pt-12--a1711";
export var pt120 = "people-module--pt-120--1eae6";
export var pt130 = "people-module--pt-130--de39e";
export var pt140 = "people-module--pt-140--3dcb0";
export var pt15 = "people-module--pt-15--4d61d";
export var pt150 = "people-module--pt-150--4e227";
export var pt16 = "people-module--pt-16--301de";
export var pt18 = "people-module--pt-18--e420f";
export var pt185 = "people-module--pt-185--7fe63";
export var pt20 = "people-module--pt-20--fa45a";
export var pt25 = "people-module--pt-25--3fb3d";
export var pt30 = "people-module--pt-30--3b1ae";
export var pt35 = "people-module--pt-35--1a07e";
export var pt40 = "people-module--pt-40--fd9d5";
export var pt45 = "people-module--pt-45--e3f27";
export var pt48 = "people-module--pt-48--08450";
export var pt5 = "people-module--pt-5--90d53";
export var pt50 = "people-module--pt-50--816c5";
export var pt55 = "people-module--pt-55--9a1f2";
export var pt60 = "people-module--pt-60--1ff66";
export var pt70 = "people-module--pt-70--1973c";
export var pt80 = "people-module--pt-80--89fba";
export var pt85 = "people-module--pt-85--babb3";
export var pt90 = "people-module--pt-90--64703";
export var pt95 = "people-module--pt-95--b532b";
export var pv0 = "people-module--pv-0--696d7";
export var pv10 = "people-module--pv-10--daff2";
export var pv100 = "people-module--pv-100--0c6b2";
export var pv105 = "people-module--pv-105--c0a0f";
export var pv110 = "people-module--pv-110--b8871";
export var pv115 = "people-module--pv-115--1a120";
export var pv12 = "people-module--pv-12--90a83";
export var pv120 = "people-module--pv-120--7f268";
export var pv130 = "people-module--pv-130--ec46f";
export var pv140 = "people-module--pv-140--100f0";
export var pv15 = "people-module--pv-15--38254";
export var pv150 = "people-module--pv-150--cb149";
export var pv16 = "people-module--pv-16--8719d";
export var pv18 = "people-module--pv-18--a8e82";
export var pv185 = "people-module--pv-185--eebd8";
export var pv20 = "people-module--pv-20--c8de3";
export var pv25 = "people-module--pv-25--69beb";
export var pv30 = "people-module--pv-30--08c71";
export var pv35 = "people-module--pv-35--a4fdf";
export var pv40 = "people-module--pv-40--37362";
export var pv45 = "people-module--pv-45--44171";
export var pv48 = "people-module--pv-48--f22a6";
export var pv5 = "people-module--pv-5--e268e";
export var pv50 = "people-module--pv-50--6cef3";
export var pv55 = "people-module--pv-55--73a10";
export var pv60 = "people-module--pv-60--967be";
export var pv70 = "people-module--pv-70--a05ac";
export var pv80 = "people-module--pv-80--a4d32";
export var pv85 = "people-module--pv-85--2f36e";
export var pv90 = "people-module--pv-90--806b6";
export var pv95 = "people-module--pv-95--1f78c";
export var text = "people-module--text--9b4c0";
export var textContainer = "people-module--textContainer--7d522";
export var title = "people-module--title--a088d";
export var wrapper = "people-module--wrapper--1227c";