import React from "react";
import { Link } from "gatsby";
import moment from "moment";
import { compareDates } from "helpers/compareDates";
import * as styles from "./vacantPositions.module.scss";
import { getStaticImgProps } from "helpers/getImgProps";

const VacantPositions = ({ title, positions }) => {
  const today = moment().format("YYYY-MM-DD");
  const positionList = positions
    .filter((position) => compareDates(today, position.dueDate))
    .map(
      (
        {
          id,
          slug,
          name,
          country,
          jobType,
          dueDate,
          publishedDate,
          arrowRight,
        },
        index
      ) => {
        return (
          <div
            key={index}
            className={`${styles.vacantPositionWrapper} d-flex justify-content-center mb-20 pa-35`}
          >
            <div className={`${styles.arrow} mr-50`}>
              <Link to={`/career/${id}/${slug}`}>
                <img {...getStaticImgProps(arrowRight)} />
              </Link>
            </div>
            <div className={styles.positionTitle}>
              <p className={`${styles.title}`}>{name}</p>
              <p className={styles.subtitle}>
                {jobType} ・ {country}
              </p>
            </div>
            <div className={`${styles.date} d-flex justify-content-between`}>
              <p>
                <span>Published: </span>
                {publishedDate} ・
              </p>
              <p>
                <span>Due date yo apply:</span>
                {dueDate}
              </p>
            </div>
          </div>
        );
      }
    );
  return (
    <div className={styles.wrapper}>
      <p className={`${styles.title} mb-60`} id="vacantPos">
        {title}
      </p>
      <div>{positionList}</div>
    </div>
  );
};

export default VacantPositions;
