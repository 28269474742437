import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { getImgProps } from "helpers/getImgProps";
import * as styles from "./people.module.scss";

const People = ({ text, image }) => {
  return (
    <div className={styles.wrapper}>
      <div
        className={`${styles.people} d-flex flex-md-row flex-column justify-content-md-between align-items-md-center`}
      >
        <div className={styles.imgWrapper}>
          <GatsbyImage {...getImgProps(image)} />
        </div>
        <div className={styles.textContainer}>
          <div className={styles.bgWhite}></div>
          <p className={styles.text}>{text}</p>
        </div>
      </div>
    </div>
  );
};

export default People;
