import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { getImgProps } from "helpers/getImgProps";
import * as styles from "./joinTeam.module.scss";

const JoinTeam = ({ title, image, text, mainTitle }) => {
  return (
    <div className={styles.wrapper}>
      <p className={`${styles.mainTitle} mt-70 mb-35`}>{mainTitle}</p>
      <div className="mb-30">
        <GatsbyImage {...getImgProps(image)} />
      </div>
      <p className={`${styles.text} mb-15`}>{text}</p>
      <p className={`${styles.text} mb-30`}>{title}</p>
    </div>
  );
};

export default JoinTeam;
