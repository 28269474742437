export const getCareerPageProps = ({
  strapiCareer: {
    seo,
    title,
    joinTeam,
    impact,
    people,
    vacantPositions,
    letsTalk,
  },
}) => {
  return {
    seo,
    title,
    joinTeam,
    impact,
    people,
    vacantPositions,
    letsTalk,
  };
};
