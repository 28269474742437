import React from "react";
import { graphql } from "gatsby";
import Seo from "components/seo";
import { getCareerPageProps } from "helpers/getCareerPageProps";
import LetsTalkComponent from "components/letsTalkComponent";
import JoinTeam from "components/joinTeam";
import People from "components/people";
import Impact from "components/impact";
import VacantPositions from "components/vacantPositions";
import Layout from "components/layout";

const Careers = ({ data }) => {
  const { seo, title, joinTeam, impact, people, vacantPositions, letsTalk } =
    getCareerPageProps(data);
  return (
    <Layout>
      <Seo {...seo} />
      <JoinTeam {...joinTeam} mainTitle={title} />
      <People {...people} />
      <Impact {...impact} />
      <VacantPositions {...vacantPositions} />
      <LetsTalkComponent {...letsTalk} />
    </Layout>
  );
};

export const query = graphql`
  query CareerPageData {
    strapiCareer {
      seo {
        metaTitle
        metaDescription
        shareImage {
          alternativeText
          localFile {
            url
          }
        }
      }
      title
      joinTeam {
        title
        text
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      impact {
        text
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      people {
        text
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      vacantPositions {
        title
        positions {
          country
          dueDate
          jobType
          name
          slug
          id
          publishedDate
          arrowRight {
            alternativeText
            localFile {
              url
            }
          }
        }
      }
      letsTalk {
        title
        subtitle
        btn
        bgDark
        address
        btnArrow {
          alternativeText
          localFile {
            url
          }
        }
        preezmaLogo {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
export default Careers;
